import './award-list.scss';
import { Slider } from '../slider/slider';

class AwardList {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-awardlist',
            items: 'data-awardlist="slides"',
            onInit: null,
            offset: 50,
            prevnext: true,
            slidesPerView: 2,
            slidesPerGroup: 2,
            breakpoints: {
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                1023: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1279: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                1439: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                }
            }
        };

        this.settings = Object.assign({}, defaults, options);
        this.$awardList = element;
        this.slider = '';
        this.awardListResizer = '';
    }

    initialize () {
        this.initSlider(this.$awardList);
    }

    checkNavigationButtons ($slides) {
        if ($slides.querySelector('[data-awardlist="prev"]').classList.contains('swiper-button-disabled') && $slides.querySelector('[data-awardlist="next"]').classList.contains('swiper-button-disabled')) {
            $slides.classList.add('slider--nav-hide');
        } else {
            $slides.classList.remove('slider--nav-hide');
        }
    }

    initSlider (slider) {
        this.slider = new Slider(slider, {
            initAttr: this.settings.initAttr,
            autoplay: false,
            breakpoints: this.settings.breakpoints,
            crossfade: false,
            calculateHeight: false,
            slidesPerView: this.settings.slidesPerView,
            slidesPerGroup: this.settings.slidesPerGroup,
            prevnext: this.settings.prevnext,
            speed: 750,
            effect: 'slide',
            onInit: () => {
                this.awardListResizer = window.setTimeout(() => {
                    this.checkNavigationButtons(this.$awardList.querySelector('[' + this.settings.items + ']'));
                    this.slider.update();
                }, 500);
            },
            onResize: ($slider) => {
                clearTimeout(this.awardListResizer);
                this.awardListResizer = window.setTimeout(() => {
                    this.checkNavigationButtons(this.$awardList.querySelector('[' + this.settings.items + ']'));
                    this.slider.update();
                }, 500);
            }
        });
    }
}

export { AwardList };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$awardList = $context.querySelectorAll('[data-awardlist="root"]');
        for (let i = 0; i < $$awardList.length; i++) {
            const $awardList = new AwardList($$awardList[i]);
            $awardList.initialize();
        }
    }
});
